import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BannerDetail, CreateBanner } from '@core/models/interfaces/admin-content/admin-content';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private baseUrl = `${environment.API_URL}/crm/banners`;

  constructor(private httpClient: HttpClient) {}

  getBannerManagement(params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<BannerDetail>>(`${this.baseUrl}/management`, { params: { ...params } });
  }

  createBanner(body: CreateBanner) {
    return this.httpClient.post<ResponseApi<BannerDetail>>(this.baseUrl, body);
  }

  deleteBanner(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateBanner(id: string, body: CreateBanner) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, body);
  }

  getDetailBanner(id: string) {
    return this.httpClient.get<ResponseApi<BannerDetail>>(`${this.baseUrl}/${id}`);
  }
}
