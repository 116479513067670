export enum BANNER_LOCATION {
  HOME = 'HOME',
  SIGN_IN = 'SIGNIN',
  PROFILE = 'PROFILE',
  LOGO = 'LOGO',
  COVER = 'COVER'
}

export enum ADMIN_CONTENT_TAB {
  POST = 'post',
  LINK = 'link',
  BANNER = 'banner'
}

export enum AdminContentSortFieldEnum {
  TITLE = 'title',
  CREATED_BY = 'createdBy.fullName',
  LOCATION = 'location',
  CREATED_AT = 'createdAt',
  UPDATED_BY = 'updatedBy.fullName'
}
